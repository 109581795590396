import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"

import Reel from '../../images/icons/reel.svg'
import ReelWoCable from '../../images/icons/reelWoCable.svg'
import TruckIcon from '../../images/icons/truck.svg'
import FactoryCodeIcon from '../../images/icons/factoryCodes.svg'

const style = theme => ({
  card: {
    textAlign:'center',
    fontWeight: '600'
  },
  cardMedia: {
    width: '100px',
    height: '100px',
    margin: '16px auto 0 auto'
  }
})

const GuidesTemplate = ({classes}) => (
  <Layout>
    <SEO title="Справочники по кабелям, проводам, ПУЭ" />
    <main>
      <h1>Справочник</h1>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Card className={classes.card} variant="outlined">
            <Link to={`/guides/cableLength/`}>
              <CardMedia
                image={Reel}
                title="Длины кабеля и провода, наматываемого на барабан"
                className={classes.cardMedia}
              />
              <CardContent>
                Длины кабеля и провода, наматываемого на барабан
              </CardContent>
            </Link>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card className={classes.card} variant="outlined">
            <Link to={`/guides/vehicleCableReel/`}>
              <CardMedia
                image={TruckIcon}
                title="Размещение барабанов в транспортном средстве"
                className={classes.cardMedia}
              />
              <CardContent>
                Размещение барабанов в транспортном средстве
              </CardContent>
            </Link>
          </Card>
        </Grid>
        <Grid className={classes.card} item md={6} xs={12}>
          <Card variant="outlined">
            <Link to={`/guides/cableReel/`}>
              <CardMedia
                image={ReelWoCable}
                title="Размеры, вес и объем кабельных барабанов"
                className={classes.cardMedia}
              />
              <CardContent>
                Размеры, вес и объем кабельных барабанов
              </CardContent>
            </Link>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card className={classes.card} variant="outlined">
            <Link to={`/guides/guidesCableManufacturer/`}>
              <CardMedia
                image={FactoryCodeIcon}
                title="Коды кабельных заводов России и стран СНГ"
                className={classes.cardMedia}
              />
              <CardContent>
                Коды кабельных заводов России и стран СНГ
              </CardContent>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </main>

  </Layout>
)

GuidesTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(GuidesTemplate)

